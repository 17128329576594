import { action } from '@solidjs/router';
import { getApiClient } from './get-api-client';
import { formDataToObject } from './form-data';
import type { Action } from '@solidjs/router';
import type { AnyVariables, DocumentInput, OperationResult } from '@urql/core';

type QueryBaseOptions<Variables extends AnyVariables = AnyVariables> = {
	transform?: (data: FormData) => Variables;
};

export function queryAction<Data = unknown, Variables extends AnyVariables = AnyVariables>(
	/**
	 * The query to run
	 */
	query: DocumentInput<Data, Variables>,
	opts?: QueryBaseOptions<Variables>,
): Action<[data: FormData], OperationResult<Data, Variables | Record<string, string>>> {
	return action(
		async (data: FormData) => {
			const submitData = formDataToObject(query, data, opts?.transform);
			const res = await getApiClient().query(query, submitData);
			return res;
		},
		// @ts-expect-error
		query.definitions.reduce((memo, def) => `${memo}${def.name.value}`, ''),
	);
}
