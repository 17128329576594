import { Show, createSignal } from 'solid-js';
import { ActivityIndicator, Button, FieldDescription, Input, Label, TextField } from '@troon/ui';
import { useAction, useSubmission } from '@solidjs/router';
import debounce from 'debounce';
import { Icon } from '@troon/icons';
import { useAuthStore } from '../flow';
import { gql, mutationAction, useMutation } from '../../../graphql';

export function ForgotPassword() {
	const [store, setStore] = useAuthStore();
	const [fakeSubmitting, setFakeSubmitting] = createSignal(false);

	const resetPasswordAction = useMutation(resetPassword);
	const handleReset = useAction(resetPasswordAction);
	const resetSubmission = useSubmission(resetPasswordAction);

	const handleChange = debounce((value: string) => {
		setStore('data', 'confirmationCode', value);
	}, 100);

	return (
		<form
			class="flex flex-col gap-4"
			onSubmit={(e) => {
				e.preventDefault();
				e.stopPropagation();
				setFakeSubmitting(true);
				setTimeout(() => {
					setFakeSubmitting(false);
					setStore('step', '/auth/reset/password');
				}, 500);
			}}
		>
			<input type="hidden" name="email" value={store.data.email} />

			<TextField name="confirmationCode">
				<Label>Confirmation code</Label>
				<Input value={store.data.confirmationCode} onInput={(e) => handleChange(e.currentTarget.value)} />
				<FieldDescription>Enter the confirmation code found in the email.</FieldDescription>
				<FieldDescription>
					<p class="flex flex-row items-center text-sm">
						Didn't receive the email?{' '}
						<Button
							type="button"
							class="inline-flex max-w-min shrink text-nowrap p-px font-normal normal-case"
							appearance="transparent"
							onClick={(e) => {
								e.preventDefault();
								e.stopPropagation();
								const data = new FormData();
								data.set('email', store.data.email!);
								data.set('redirect', store.redirect);
								handleReset(data);
							}}
						>
							Resend it now
						</Button>
						.
						<Show when={resetSubmission.pending}>
							<ActivityIndicator class="ms-2 size-4 text-brand" />
						</Show>
						<Show when={resetSubmission.result}>
							<Icon name="circle-check" class="ms-2 size-4 text-green-500" title="Confirmation code sent!" />
						</Show>
					</p>
				</FieldDescription>
			</TextField>

			<Button type="submit" disabled={fakeSubmitting()}>
				<Show when={fakeSubmitting()}>
					<ActivityIndicator aria-label="Loading" class="me-2 size-4" />
				</Show>
				Check code
			</Button>
		</form>
	);
}

const forgotPasswordMutation = gql(`
mutation forgotPassword($email: String!, $redirect: String) {
	forgotPassword(email: $email, redirectUrl: $redirect) {
		medium
		destination
	}
}`);

const resetPassword = mutationAction(forgotPasswordMutation, {
	track: { event: 'passwordResetCode' },
});
