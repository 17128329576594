import { Button, Input, Label, TextField } from '@troon/ui';
import { useTrackEvent } from '@troon/analytics';
import debounce from 'debounce';
import { Switch, Match } from 'solid-js';
import { Navigate } from '@solidjs/router';
import { useAuthStore } from '../flow';

export function Name() {
	const [store, setStore] = useAuthStore();
	const trackEvent = useTrackEvent();

	const handleSetFirstName = debounce((value: string) => {
		setStore('data', 'firstName', value);
	});
	const handleSetLastName = debounce((value: string) => {
		setStore('data', 'lastName', value);
	});

	return (
		<Switch>
			<Match when={!store.data.email || !store.data.password}>
				<Navigate href="/auth" />
			</Match>
			<Match when={true}>
				<form
					class="flex flex-col gap-4"
					onSubmit={() => {
						if (store.data.firstName && store.data.lastName) {
							trackEvent('signupEnteredName', { email: store.data.email });
							setStore('step', '/auth/join/postal-code');
						}
					}}
				>
					<TextField name="firstName">
						<Label>First name</Label>
						<Input onInput={(e) => handleSetFirstName(e.currentTarget.value)} />
					</TextField>

					<TextField name="lastName">
						<Label>Last name</Label>
						<Input onInput={(e) => handleSetLastName(e.currentTarget.value)} />
					</TextField>

					<Button type="submit" disabled={!store.data.firstName || !store.data.lastName}>
						Continue
					</Button>
				</form>
			</Match>
		</Switch>
	);
}
