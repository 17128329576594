import type { OperationResult, OperationResultSource } from '@urql/core';

export async function timeoutRetry<T extends OperationResultSource<OperationResult<unknown>>>(
	fn: () => T,
	tryNum: number = 1,
	maxTries: number = 15,
): Promise<T> {
	return new Promise((resolve, reject) => {
		setTimeout(async () => {
			try {
				const res = await fn();
				if (!res.error || tryNum >= maxTries) {
					resolve(res);
					return;
				}
			} catch (e) {
				reject(e);
				return;
			}
			timeoutRetry(fn, tryNum + 1, maxTries);
		}, 2_000);
	});
}
